/* eslint-disable prettier/prettier */
import axios from 'axios';

export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

export const dotNotationReference = (path: string, obj: any) => {
  return path.split('.').reduce((o, i) => o[i], obj);
};

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string): boolean => {
  // eslint-disable-next-line
  return true;
};

export const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  encodeBinary: function (input: any) {
    let output = '';
    let bytebuffer;
    const encodedCharIndexes = new Array(4);
    let inx = 0;
    let paddingBytes = 0;

    while (inx < input.length) {
      // Fill byte buffer array
      bytebuffer = new Array(3);
      for (let jnx = 0; jnx < bytebuffer.length; jnx++)
        if (inx < input.length) bytebuffer[jnx] = input.charCodeAt(inx++) & 0xff;
        // throw away high-order byte, as documented at: https://developer.mozilla.org/En/Using_XMLHttpRequest#Handling_binary_data
        else bytebuffer[jnx] = 0;

      // Get each encoded character, 6 bits at a time
      // index 1: first 6 bits
      encodedCharIndexes[0] = bytebuffer[0] >> 2;
      // index 2: second 6 bits (2 least significant bits from input byte 1 + 4 most significant bits from byte 2)
      encodedCharIndexes[1] = ((bytebuffer[0] & 0x3) << 4) | (bytebuffer[1] >> 4);
      // index 3: third 6 bits (4 least significant bits from input byte 2 + 2 most significant bits from byte 3)
      encodedCharIndexes[2] = ((bytebuffer[1] & 0x0f) << 2) | (bytebuffer[2] >> 6);
      // index 3: forth 6 bits (6 least significant bits from input byte 3)
      encodedCharIndexes[3] = bytebuffer[2] & 0x3f;

      // Determine whether padding happened, and adjust accordingly
      paddingBytes = inx - (input.length - 1);
      switch (paddingBytes) {
        case 2:
          // Set last 2 characters to padding char
          encodedCharIndexes[3] = 64;
          encodedCharIndexes[2] = 64;
          break;
        case 1:
          // Set last character to padding char
          encodedCharIndexes[3] = 64;
          break;
        default:
          break; // No padding - proceed
      }
      // Now we will grab each appropriate character out of our keystring
      // based on our index array and append it to the output string
      for (let jnx = 0; jnx < encodedCharIndexes.length; jnx++)
        output += this._keyStr.charAt(encodedCharIndexes[jnx]);
    }
    return output;
  },
};

export const currencyConverter = async (currency: string) => {
  const response = await axios.get(
    `https://free.currconv.com/api/v7/convert?q=${currency}_EUR&compact=ultra&apiKey=8b5cfcae5b60320e86fa`,
  );
  return response.data[`${currency}_EUR`];
};

export const adminPaths = ['/all', '/users'];

export const ratingsReleaseDate = '09-16-2021';

export const CURRENCY_CHANGE_OBJ = {
  authorizedValue: {
    key: 'authorizedValue',
    value: 0,
    conversionRate: 0,
    valueInEUR: 0,
    currency: '',
  },
  excessValue: {
    key: 'excessValue',
    value: 0,
    conversionRate: 0,
    valueInEUR: 0,
    currency: '',
  },
  jobCostEstimate: {
    key: 'jobCostEstimate',
    value: 0,
    conversionRate: 0,
    valueInEUR: 0,
    currency: '',
  },
};

export const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    outline: 'none',
  }),
  control: (provided: any) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    minHeight: 'unset',
    borderColor: 'none',
    boxShadow: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: 'fit-content',
    padding: '0px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    height: 'fit-content',
    padding: '0px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: 'fit-content',
    padding: '0px',
    alignItems: 'start',
    justifyContent: 'center',
    marginTop: '-4px',
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '150px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    width: '150px',
    overflowX: 'hidden',
    textAlign: 'start',
    zIndex: 99,
  }),
};

export const statusOptions = [
  'STATUS',
  'NEW',
  'SERVICE DONE',
  'BOOKED',
  'EXCESS_COLLECTED',
  'INVOICED',
  // 'COMPLETED',
  'CANCELLED',
];

export const authorizedOptions = ['Authorized', 'Unauthorized', 'Both'];
