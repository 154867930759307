/* eslint-disable prettier/prettier */
import { DropdownOption } from '../../new/typings';
import { selectStyles } from '../../../../helpers/utils';
import React from 'react';
import Select from 'react-select';

const HeaderComponent: React.FC<any> = ({
  queue,
  options,
  statusFilter,
  setStatusFilter,
  showDownloadIcon,
  authorizedOptions,
  authorizedFilter,
  setAuthorizedFilter,
}) => {
  return (
    <thead>
      <tr>
        <th>Claim No.</th>
        <th className="text-center">Make</th>
        <th className="text-center">Model</th>
        <th className="text-center">Registration</th>
        <th className="text-center width-120">
          {' '}
          <Select
            styles={selectStyles}
            options={authorizedOptions}
            value={authorizedFilter}
            onChange={(selectedOption: DropdownOption) => {
              setAuthorizedFilter(selectedOption);
            }}
          />{' '}
        </th>
        <th className="text-center">{queue} Member</th>
        <th className="text-center width-120">
          {' '}
          <Select
            styles={selectStyles}
            options={options}
            value={statusFilter}
            onChange={(selectedOption: DropdownOption) => {
              setStatusFilter(selectedOption);
            }}
          />{' '}
        </th>
        <th className="text-center">Days Open</th>
        <th className="text-center">Created</th>
        <th className="text-center">Updated</th>
        {showDownloadIcon && <th></th>}
        <th></th>
      </tr>
    </thead>
  );
};

export default HeaderComponent;
