/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { AllClaimsPageProps, AllClaimsPageState, IncomingPageState } from '../typings';
import { Badge, Button, Input, Label, Popover, PopoverBody, PopoverHeader } from 'reactstrap/lib';
import { DropdownOption, IProfile } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { authorizedOptions, selectStyles, statusOptions } from '../../../helpers/utils';
import { calendar, check, download, loader, user, x } from 'react-icons-kit/feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { downloadClaimInfo } from '../../../actions/update-claim';
import CompletedSidePanel from '../side-panel/CompletedSidePanel';
import DatePicker from 'react-datepicker';
import EmptyState from '../empty-state/EmptyState';
import Helmet from 'react-helmet';
import Icon from 'react-icons-kit';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingPage from '../../loading/LoadingPage';
import React, { Component } from 'react';
import Select, { ValueType } from 'react-select';
import _ from 'lodash';
import moment from 'moment';

let claimsListCurrent = 0;

class AllClaimsPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & AllClaimsPageProps,
  AllClaimsPageState
> {
  private static INITIAL_STATE: IncomingPageState = {
    activeClaim: null,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(AllClaimsPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);
    this.state = {
      ...AllClaimsPage.INITIAL_STATE,
      statusFilter: { value: 'STATUS', label: 'ALL' },
      authorizedFilter: { value: 'Both', label: 'Both' },
      partnerFilter: { value: 'Partner', label: 'Member' },
      countryFilter: { value: 'Country', label: 'Country' },
      searchStringVal: '',
      popoverOpen: false,
      dowloadLoading: {},
      isAdmin: false,
      partnerId: undefined,
    };
  }

  public selectClaim(id: string | null) {
    this.setState(AllClaimsPage.propKey('activeClaim', id));
  }

  fetchMoreData = () => {
    const next = this.props?.pagination?.take || 0;
    typeof this.props.loadMore === 'function' && this.props.loadMore(next + 10);
  };

  onSearchBtnClick = () => {
    typeof this.props.searchChange === 'function' &&
      this.props.searchChange(this.state.searchStringVal || '');
  };

  onResetSearchBtnClick = () => {
    this.setState({ searchStringVal: '' });
    typeof this.props.searchChange === 'function' && this.props.searchChange('');
  };

  options = statusOptions.map((status: string) => {
    if (status === 'STATUS') {
      return {
        value: status,
        label: 'ALL',
      };
    }
    return {
      value: status,
      label: status,
    };
  });

  authorizedOpts = authorizedOptions.map((status: string) => {
    return {
      value: status,
      label: status,
    };
  });

  openPdf = (base64str: string, id: string) => {
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
    const dowloadLoadingCurrent = { ...this.state.dowloadLoading };
    dowloadLoadingCurrent[id] = false;
    this.setState({ dowloadLoading: { ...dowloadLoadingCurrent } });
  };

  downloadProofOfWork = async (claimId: string) => {
    const dowloadLoadingCurrent = { ...this.state.dowloadLoading };
    dowloadLoadingCurrent[claimId] = true;
    try {
      this.setState({ dowloadLoading: { ...dowloadLoadingCurrent } });
      const pdfFile = await downloadClaimInfo(claimId || '', false);
      if (pdfFile) {
        this.openPdf(pdfFile, claimId);
      }
    } catch (e) {
      dowloadLoadingCurrent[claimId] = true;
      this.setState({ dowloadLoading: { ...dowloadLoadingCurrent } });
      console.error(e);
    }
  };

  componentDidUpdate = () => {
    const { partnerId: partnerIdProfile = '' } = this.props?.profile || {};
    if (this.state.partnerId !== partnerIdProfile) {
      if (partnerIdProfile === 'Admin') {
        this.setState({ isAdmin: true, partnerId: partnerIdProfile });
      } else if (partnerIdProfile !== 'Admin') {
        this.setState({ isAdmin: false, partnerId: partnerIdProfile });
      }
    }
  };

  render() {
    const {
      claims,
      loading,
      partner,
      todos,
      partnerOptions,
      partners,
      countryOptions,
      dateRangeValue,
    } = this.props;

    const { activeClaim, searchStringVal, isAdmin } = this.state;
    const claim = claims && activeClaim ? { ...claims[activeClaim], id: activeClaim } : null;
    if (loading) {
      return <LoadingPage />;
    }

    if (_.isEmpty(claims)) {
      return (
        <EmptyState
          message={
            searchStringVal !== '' || dateRangeValue.search
              ? 'No claims match your search'
              : 'There are currently no claims in the system'
          }
        />
      );
    }

    let claimsList = claims;

    if (isAdmin) {
      claimsList = _.pickBy(claims, (c) => {
        if (this.state.authorizedFilter.value === 'Authorized') {
          return c.insurer.authorized === true;
        } else if (this.state.authorizedFilter.value === 'Unauthorized') {
          return c.insurer.authorized === false;
        }
        return c;
      });

      claimsList = _.pickBy(claimsList, (c) => {
        if (this.state.partnerFilter.value !== 'Member') {
          return c.assign.partner === this.state.partnerFilter.value;
        }
        return c;
      });

      claimsList = _.pickBy(claimsList, (c) => {
        if (this.state.countryFilter.value !== 'Country') {
          return partners[c.assign.partner || '']?.country === this.state.countryFilter.value;
        }
        return c;
      });

      claimsList = _.pickBy(claimsList, (c) => {
        if (this.state.statusFilter.value !== 'STATUS') {
          return c.status === this.state.statusFilter.value;
        }
        return c;
      });
    }

    return (
      <div className={`incoming-claim-page-container ${activeClaim ? 'open' : 'closed'}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Global Lifetime Guarantee Code Verification</title>
        </Helmet>
        <div className="page-title-div"> 
        <Label>Global Lifetime Guarantee Code Verification</Label>
        </div>
        <div className="search-div">
          <Input
            name="search"
            value={this.state.searchStringVal}
            placeholder="Search Claim"
            className="search-input"
            onChange={(event) => this.setState({ searchStringVal: event.target.value })}
          />
          <Button onClick={this.onSearchBtnClick} className="search-btn">
            Search
          </Button>
          <Button
            outline
            color="secondary"
            onClick={this.onResetSearchBtnClick}
            className="search-btn">
            Reset
          </Button>
          {isAdmin ? (
            <>
              <Icon id="Popover1" icon={calendar} size={32} />
              <Popover
                placement="bottom"
                isOpen={this.state.popoverOpen}
                target="Popover1"
                toggle={() => this.setState({ popoverOpen: !this.state.popoverOpen })}>
                <PopoverHeader>Select Date Range</PopoverHeader>
                <PopoverBody>
                  <div className="date-form">
                    <div>
                      <label>From: </label>
                      <DatePicker
                        selected={new Date(dateRangeValue.fromDate)}
                        className="date-input"
                        onChange={(value: Date) => {
                          this.props.setFromDateValue(value.toString());
                        }}
                      />
                    </div>
                    <div>
                      <label>To: </label>
                      <DatePicker
                        selected={new Date(dateRangeValue.toDate)}
                        className="date-input"
                        onChange={(value: Date) => {
                          this.props.setToDateValue(value.toString());
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        outline
                        color="secondary"
                        onClick={() => {
                          this.props.searchDateRange(true);
                          this.setState({
                            popoverOpen: !this.state.popoverOpen,
                          });
                        }}
                        className="search-btn">
                        Done
                      </Button>
                      <Button
                        outline
                        color="secondary"
                        onClick={() => {
                          this.props.setFromDateValue(moment('2000-01-01').toDate().toString());
                          this.props.setToDateValue(moment('2100-01-01').toDate().toString());
                          this.props.searchDateRange(false);
                        }}
                        className="search-btn">
                        Reset
                      </Button>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>{' '}
            </>
          ) : null}
        </div>
        <div className="table-all-header">
          <div>
            <div className={isAdmin ? '' : 'width-20'}>Claim No.</div>
            <div className={isAdmin ? '' : 'width-20'}>Make</div>
            <div className={isAdmin ? '' : 'width-20'}>Model</div>
            <div className={isAdmin ? '' : 'width-20'}>Registration</div>
            {isAdmin ? (
              <>
                <div className="text-center width-7">
                  {' '}
                  <Select
                    styles={selectStyles}
                    options={this.authorizedOpts}
                    value={this.state.authorizedFilter}
                    onChange={(selectedOption: ValueType<DropdownOption>) => {
                      this.setState({ authorizedFilter: selectedOption });
                    }}
                  />{' '}
                </div>
                <div className="text-center width-7">
                  {' '}
                  <Select
                    styles={selectStyles}
                    options={partnerOptions}
                    value={this.state.partnerFilter}
                    onChange={(selectedOption: ValueType<DropdownOption>) => {
                      this.setState({ partnerFilter: selectedOption });
                    }}
                  />{' '}
                </div>
                <div className="text-center width-7">
                  {' '}
                  <Select
                    styles={selectStyles}
                    options={countryOptions}
                    value={this.state.countryFilter}
                    onChange={(selectedOption: ValueType<DropdownOption>) => {
                      this.setState({ countryFilter: selectedOption });
                    }}
                  />{' '}
                </div>
                <div className="text-center width-7">
                  {' '}
                  <Select
                    styles={selectStyles}
                    options={this.options}
                    value={this.state.statusFilter}
                    onChange={(selectedOption: ValueType<DropdownOption>) => {
                      this.setState({ statusFilter: selectedOption });
                    }}
                  />{' '}
                </div>
                <div className="text-center width-7">Days Open</div>
                <div className="text-center width-7">Created</div>
                <div className="text-center width-7">Updated</div>
              </>
            ) : null}
            <div className="text-center"></div>
            <div className="text-center"></div>
          </div>
        </div>
        <div id="list-claims" className="all-claims-list">
          {!_.isEmpty(claimsList) && (
            <InfiniteScroll
              dataLength={Object.values(claimsList).length}
              next={this.fetchMoreData}
              hasMore={
                Object.values(claimsList).length >= (this.props?.pagination?.take || 0 - 10) ||
                Object.values(claimsList).length < claimsListCurrent
                  ? true
                  : false
              }
              scrollableTarget="list-claims">
              {_.map(claimsList, (claim, id: string) => {
                if (claim) {
                  const authorizedIcon = claim && claim?.insurer.authorized ? check : x;
                  const authorizedClass =
                    claim && claim.insurer.authorized ? 'text-success' : 'text-danger';
                  const updateDate = !_.isDate(claim?._audit?.updated?.timestamp)
                    ? moment(claim?._audit?.updated?.timestamp.toDate()).format('DD MMM, YYYY')
                    : '-';
                  const createDateMoment = !_.isDate(claim?._audit?.created?.timestamp)
                    ? moment(claim?._audit?.created?.timestamp.toDate())
                    : moment();
                  const createDate = createDateMoment.format('DD MMM, YYYY');
                  const todayMoment = moment();
                  const daysOpen = Math.max(todayMoment.diff(createDateMoment, 'days'), 1);
                  return (
                    <div className="table-all-row">
                      <div
                        className={isAdmin ? '' : 'width-20'}
                        style={{ justifyContent: 'flex-start' }}>
                        <Button
                          className="table-inline-button"
                          onClick={() => {
                            isAdmin && this.selectClaim(id);
                          }}>
                          {claim?.insurer?.claimNumber}
                        </Button>
                      </div>
                      <div className={isAdmin ? 'justify-left' : 'justify-left width-20'}>
                        {claim?.vehicle?.make}
                      </div>
                      <div className={isAdmin ? 'justify-left' : 'justify-left width-20'}>
                        {claim?.vehicle?.model}
                      </div>
                      <div className={isAdmin ? 'justify-left' : 'justify-left width-20'}>
                        {claim?.vehicle?.registrationNumber}
                      </div>
                      {isAdmin ? (
                        <>
                          <div className="text-center width-7">
                            <Icon icon={authorizedIcon} className={authorizedClass} />
                          </div>
                          <div className="text-center width-7">{claim?.assign?.partner}</div>
                          <div className="text-center width-7">
                            {partners[claim?.assign?.partner || '']?.country
                              ? partners[claim?.assign?.partner || ''].country
                              : ''}
                          </div>
                          <div className="text-center width-7">{claim?.status}</div>
                          <div className="text-center width-7">
                            <Badge color="secondary" size="lg">
                              {daysOpen}
                            </Badge>
                          </div>
                          <div className="text-center width-7">{createDate}</div>
                          <div className="text-center width-7">{updateDate}</div>
                        </>
                      ) : null}
                      <div className="text-center">
                        {!claim?.active && claim?.status === 'COMPLETED' ? (
                          <>
                            {!this.state.dowloadLoading[id] ? (
                              <div title="Download Lifetime Guarantee Certificate">
                                <Icon
                                  onClick={this.downloadProofOfWork.bind(this, id)}
                                  icon={download}
                                  size={16}
                                />
                              </div>
                            ) : (
                              <div>
                                <Icon icon={loader} className="spin" size={16} />
                              </div>
                            )}
                          </>
                        ) : (
                          false
                        )}
                      </div>
                      <div className="text-center">
                        {claim?.assign?.owners?.partner ? (
                          <div className="claim-user-icon">
                            <Icon icon={user} size={10} />
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  );
                } else return null;
              })}
            </InfiniteScroll>
          )}
        </div>
        <CompletedSidePanel
          claim={claim}
          todos={todos}
          partner={partner}
          onClose={() => this.selectClaim(null)}
        />
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
    pagination,
    searchString,
    dateRangeValue,
  }: {
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
    dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  } = state;
  return {
    profile: profile,
    pagination,
    searchString,
    dateRangeValue,
  };
};

const mapQueryToProps = ({
  profile,
  pagination,
  searchString,
  dateRangeValue,
  firestore,
}: {
  profile: IProfile;
  pagination: { skip: number; take: number };
  searchString: { searchString: string };
  dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  firestore: FirestoreReducer.Reducer;
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  let fromDate = moment('2000-01-01').toDate();
  let toDate = moment('2100-01-01').toDate();
  if (dateRangeValue.search) {
    fromDate = moment(dateRangeValue.fromDate).toDate();
    toDate = moment(dateRangeValue.toDate).toDate();
  }
  fromDate = firestore.Timestamp.fromDate(fromDate);
  toDate = firestore.Timestamp.fromDate(toDate);
  if (searchString.searchString === '') {
    return [
      {
        collection: 'claims',
        where: [
          ['_audit.created.timestamp', '>=', fromDate],
          ['_audit.created.timestamp', '<=', toDate],
        ],
        limit: pagination.take,
        orderBy: ['_audit.created.timestamp', 'desc'],
        storeAs: 'allClaims',
      },
      {
        collection: 'config',
        doc: 'todos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        storeAs: 'partners',
      },
    ];
  } else {
    return [
      {
        collection: 'claims',
        where: ['insurer.claimNumber', '==', searchString.searchString],
        storeAs: 'allClaims',
      },
      {
        collection: 'claims',
        where: ['vehicle.registrationNumber', '==', searchString.searchString],
        storeAs: 'claimsReg',
      },
      {
        collection: 'config',
        doc: 'todos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        storeAs: 'partners',
      },
    ];
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  loadMore: (skip: number) => {
    dispatch({
      type: 'LOAD_MORE',
      value: skip,
    });
  },
  searchChange: (searchVal: string) => {
    dispatch({
      type: 'SEARCH_CHANGE',
      value: searchVal,
    });
  },
  setToDateValue: (toDateValue: string) => {
    dispatch({
      type: 'SET_TO_DATE_VALUE',
      value: toDateValue,
    });
  },
  setFromDateValue: (fromDateValue: string) => {
    dispatch({
      type: 'SET_FROM_DATE_VALUE',
      value: fromDateValue,
    });
  },
  searchDateRange: (value: boolean) => {
    dispatch({
      type: 'SEARCH_DATE_RANGE',
      value: value,
    });
  },
});

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    pagination,
    searchString,
    dateRangeValue,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
    dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  } = state;
  let partner = '';
  const partners = firestore.data.partners;
  let claims = firestore.data.allClaims;
  if (searchString.searchString !== '') {
    if (firestore.data.allClaims) {
      claims = firestore.data.allClaims;
    } else if (firestore.data.claimsReg) {
      claims = firestore.data.claimsReg;
    }
  }
  const todosConfig = firestore.data.todosConfig;
  const loading = !isLoaded(claims) || !isLoaded(todosConfig) || !isLoaded(partners);
  let todos = null;
  const partnerOptions: Array<{ value: string; label: string }> = [];
  const countryOptions: Array<{ value: string; label: string }> = [];
  if (!loading) {
    const masterTodo = todosConfig.todos;
    partner = partners['TouringGlass'];
    const partnerConfig = partners['TouringGlass']?.claimTemplate?.todos;
    todos = _.assign({}, masterTodo, partnerConfig);
    if (claims && claimsListCurrent < Object.values(claims).length) {
      claimsListCurrent = Object.values(claims).length;
    }
    _.map(partners, (partner, id) => {
      partnerOptions.push({ value: id, label: partner.name });
      countryOptions.push({ value: partner.country, label: partner.country });
    });
    partnerOptions.push({ value: 'Partner', label: 'Member' });
    countryOptions.push({ value: 'Country', label: 'Country' });
  }
  return {
    loading,
    claims,
    todos,
    partner,
    partners,
    pagination,
    partnerOptions,
    countryOptions,
    dateRangeValue,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(AllClaimsPage);
