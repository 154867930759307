import { ContextType } from './types';
import { initialState, postReducer } from './reducer';
import React, { createContext, useReducer } from 'react';

export const NewsFeedContext = createContext<Partial<ContextType>>({});

export const NewsFeedProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(postReducer, initialState);

  const providerValue: ContextType = {
    feedState: state,
    feedDispatch: dispatch,
  };

  return <NewsFeedContext.Provider value={providerValue}>{children}</NewsFeedContext.Provider>;
};
