/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import { Button, Input } from 'reactstrap/lib';
import { DropdownOption } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IncomingPageState, OutgoingPageProps, OutgoingPageState } from '../typings';
import { Label, Table } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { authorizedOptions } from '../../../helpers/utils';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { downloadClaimInfo } from '../../../actions/update-claim';
import CompletedSidePanel from '../side-panel/CompletedSidePanel';
import EmptyState from '../empty-state/EmptyState';
import HeaderComponent from '../shared/table/HeaderComponent';
import Helmet from 'react-helmet';
import LoadingPage from '../../loading/LoadingPage';
import OutgoingRow from '../shared/table/OutgoingRow';
import React, { Component } from 'react';
import _ from 'lodash';

const statusOptions = ['STATUS', 'COMPLETED', 'CANCELLED'];

class CompletedClaimPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & OutgoingPageProps,
  OutgoingPageState
> {
  private static INITIAL_STATE: IncomingPageState = {
    activeClaim: null,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(CompletedClaimPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);
    this.state = {
      ...CompletedClaimPage.INITIAL_STATE,
      statusFilter: { value: 'STATUS', label: 'ALL' },
      authorizedFilter: { value: 'Both', label: 'Both' },
      searchStringVal: '',
      dowloadLoading: {},
    };
    props.searchChange('');
  }

  public selectClaim(id: string | null) {
    this.setState(CompletedClaimPage.propKey('activeClaim', id));
  }

  options = statusOptions.map((status: string) => {
    if (status === 'STATUS') {
      return {
        value: status,
        label: 'ALL',
      };
    }
    return {
      value: status,
      label: status,
    };
  });

  authorizedOpts = authorizedOptions.map((status: string) => {
    return {
      value: status,
      label: status,
    };
  });

  setStatusFilter = (value: DropdownOption) => {
    this.setState({ statusFilter: value });
  };

  setAuthorizedFilter = (value: DropdownOption) => {
    this.setState({ authorizedFilter: value });
  };

  onSearchBtnClick = () => {
    typeof this.props.searchChange === 'function' &&
      this.props.searchChange(this.state.searchStringVal || '');
  };

  onResetSearchBtnClick = () => {
    this.setState({ searchStringVal: '' });
    typeof this.props.searchChange === 'function' && this.props.searchChange('');
  };

  openPdf = (base64str: string, id: string) => {
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
    const dowloadLoadingCurrent = { ...this.state.dowloadLoading };
    dowloadLoadingCurrent[id] = false;
    this.setState({ dowloadLoading: { ...dowloadLoadingCurrent } });
  };

  downloadProofOfWork = async (claimId: string) => {
    const dowloadLoadingCurrent = { ...this.state.dowloadLoading };
    dowloadLoadingCurrent[claimId] = true;
    try {
      this.setState({ dowloadLoading: { ...dowloadLoadingCurrent } });
      const pdfFile = await downloadClaimInfo(claimId || '', false);
      if (pdfFile) {
        this.openPdf(pdfFile, claimId);
      }
    } catch (e) {
      dowloadLoadingCurrent[claimId] = true;
      this.setState({ dowloadLoading: { ...dowloadLoadingCurrent } });
      console.error(e);
    }
  };

  render() {
    const { claims, loading, partner, todos } = this.props;
    const { activeClaim } = this.state;
    const claim = claims && activeClaim ? { ...claims[activeClaim], id: activeClaim } : null;
    if (loading) {
      return <LoadingPage />;
    }
    if (_.isEmpty(claims)) {
      return <EmptyState message="There are currently no completed claims" />;
    }

    let claimsList = _.pickBy(claims, (c) => {
      if (this.state.authorizedFilter.value === 'Authorized') {
        return c.insurer.authorized === true;
      } else if (this.state.authorizedFilter.value === 'Unauthorized') {
        return c.insurer.authorized === false;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.statusFilter.value !== 'STATUS') {
        return c.status === this.state.statusFilter.value;
      }
      return c;
    });

    return (
      <div className={`incoming-claim-page-container ${activeClaim ? 'open' : 'closed'}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Completed Claims</title>
        </Helmet>
        <div className="page-title-div"> 
        <Label>Completed Claims</Label>
        </div>
        <div className="search-div">
          <Input
            name="search"
            value={this.state.searchStringVal}
            placeholder="Search Claim"
            className="search-input"
            onChange={(event) => this.setState({ searchStringVal: event.target.value })}
          />
          <Button onClick={this.onSearchBtnClick} className="search-btn">
            Search
          </Button>
          <Button
            outline
            color="secondary"
            onClick={this.onResetSearchBtnClick}
            className="search-btn">
            Reset
          </Button>
        </div>
        <Table>
          <HeaderComponent
            statusFilter={this.state.statusFilter}
            setStatusFilter={this.setStatusFilter}
            options={this.options}
            queue="Destination"
            showDownloadIcon
            authorizedOptions={this.authorizedOpts}
            authorizedFilter={this.state.authorizedFilter}
            setAuthorizedFilter={this.setAuthorizedFilter}
          />
          <tbody>
            {_.map(claimsList, (c, id: string) => (
              <OutgoingRow
                key={`completed-row-${id}`}
                claim={c}
                onClick={() => this.selectClaim(id)}
                downloadProofOfWork={this.downloadProofOfWork}
                claimId={id}
                dowloadLoading={this.state.dowloadLoading}
                showDownloadIcon
                partner={partner.name}
                allowRating={partner.allowRating || false}
              />
            ))}
          </tbody>
        </Table>
        <CompletedSidePanel
          claim={claim}
          todos={todos}
          partner={partner}
          onClose={() => this.selectClaim(null)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  searchChange: (searchVal: string) => {
    dispatch({
      type: 'SEARCH_CHANGE',
      value: searchVal,
    });
  },
});

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
    searchString,
  }: {
    firebase: FirebaseReducer.Reducer;
    searchString: { searchString: string };
  } = state;
  return {
    profile: profile,
    searchString,
  };
};

const mapQueryToProps = ({
  profile,
  searchString,
}: {
  searchString: { searchString: string };
  profile: any;
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  if (searchString.searchString === '') {
    return [
      {
        collection: 'claims',
        where: [
          ['assign.partners', 'array-contains', profile.partnerId],
          ['status', '==', 'COMPLETED'],
          ['active', '==', false],          
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'config',
        doc: 'todos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  } else {
    return [
      {
        collection: 'claims',
        where: [
          ['insurer.claimNumber', '==', searchString.searchString],
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'claims',
        where: [
          ['vehicle.registrationNumber', '==', searchString.searchString],
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
        storeAs: 'claimsReg',
      },
      {
        collection: 'config',
        doc: 'todos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  }
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    searchString,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    searchString: { searchString: string };
  } = state;
  const partner = firestore.data.partner;
  const partners = firestore.data.partners;
  let claims = firestore.data.claims;
  if (searchString.searchString !== '') {
    if (firestore.data.claims) {
      claims = firestore.data.claims;
    } else if (firestore.data.claimsReg) {
      claims = firestore.data.claimsReg;
    }
  }
  const todosConfig = firestore.data.todosConfig;
  const loading = !isLoaded(claims) || !isLoaded(todosConfig) || !isLoaded(partner);
  let todos = null;
  if (!loading) {
    const masterTodo = todosConfig.todos;
    const partnerConfig = partner.claimTemplate.todos;
    todos = _.assign({}, masterTodo, partnerConfig);
  }
  return {
    loading,
    claims,
    todos,
    partner,
    partners,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(CompletedClaimPage);
