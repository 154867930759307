/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
/* eslint-disable import/named */
import '../../styles/DashboardPage.scss';
import '../../styles/Ticker.scss';
import { Button } from 'reactstrap';
import { DashboardPageProps } from './typings';
import { FirebaseReducer, FirestoreReducer, getFirebase, isLoaded } from 'react-redux-firebase';
import { Icon } from 'react-icons-kit';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getNewsFeed } from '../../../src/actions/new-claim';
import { power } from 'react-icons-kit/feather';
import CompletedClaims from './tiles/CompletedClaims';
import Helmet from 'react-helmet';
import IncomingClaims from './tiles/IncomingClaims';
import LoadingPage from '../loading/LoadingPage';
import OutgoingClaims from './tiles/OutgoingClaims';
import React, { Component, useEffect } from 'react';
import icon from '../../images/news-icon.png';
import moment from 'moment';
import newsIcon from '../../images/News.svg';

import NewsTicker from '../newsFeed/NewsTicker/index';
import Ticker from '../newsFeed/Ticker/index';

// import Ticker, { NewsTicker } from 'nice-react-ticker';

class DashboardPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & DashboardPageProps
> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: null
    }
    this.logout = this.logout.bind(this);
    this.getNewsFeeds = this.getNewsFeeds.bind(this);
  }

  logout() {
    this.props.firebase.logout();
  }

  getNewsFeeds = async () => {
    try {
      let resp: any;
      await getNewsFeed().then((response: any) => {
        resp = response;
      });
      this.setState({ data: resp.newsFeedData.data });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { incomingClaims, outgoingClaims, completedClaims, partner, loading } = this.props;
    this.getNewsFeeds();
    const newss = [];
    const asd = [];
    if (this.state !== null && this.state.data !== null) {
      for (let i = 0; i < this.state.data.length; i++) {
        const date = moment(this.state.data[i][0]._audit.created.timestamp).toDate().toISOString();
        const createdDate = moment(date).utc().format('DD/MM/YYYY');
        // newss.push(` */ ${createdDate}: ${this.state.data[i][0].news.title} - ${this.state.data[i][0].news.news} By ${this.state.data[i][0].origin} /*` + `${''}`  )
        newss.push(<div>
          <img src={newsIcon} />
          {` */ ${createdDate}: ${this.state.data[i][0].news.title} - ${this.state.data[i][0].news.news} By ${this.state.data[i][0].origin} /*`}
        </div>)

    //     asd.push(<Ticker isNewsTicker={true}>
    //         <NewsTicker id={i + 1} icon={newsIcon} date={`${createdDate}: `} title={`${this.state.data[i][0].news.title} - `} news={this.state.data[i][0].news.news} />
    // </Ticker>);

    asd.push(
            <NewsTicker id={i + 1} title={`${this.state.data[i][0].news.news} By Admin `} meta={`${createdDate}`}/>
    );
      }
    }

    if (loading) {
      return <LoadingPage />;
    }
    return (
      <div className="dashboard-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Dashboard</title>
        </Helmet>
        <div className="user-container">
          Hi, {this.props.auth.displayName || this.props.auth.email}
          <Button className="logout-button" onClick={this.logout}>
            <Icon icon={power} />
          </Button>
        </div>

        <div className="dashboard-tile-container">
          <IncomingClaims claims={incomingClaims} partner={partner} />
          <OutgoingClaims claims={outgoingClaims} partner={partner} />
          <CompletedClaims claims={completedClaims} partner={partner} />
        </div>
        {newss.length !== 0 ?
          <div id="breaking-news-container">
            {/* <div id="breaking-news-colour" className="slideup animated">
            </div>*/}
            <span className="breaking-news-title delay-animated slidein">
              BREAKING NEWS
            </span>
            {/* {newss.map((news) => (
              <a className="breaking-news-headline delay-animated2 fadein marquee .scroll-animated"> */}
               <div className="newsticker">
               {asd.map((sdf) =>
          <Ticker isNewsTicker={true} slideSpeed={25}>
            
            {asd}
           
          {/* <NewsTicker id={3} icon={newsIcon} title="Blue passports to be issued to Brits for the first time in decades next month decades next month decades next month decades next month" url=" https://metro.co.uk/2020/02/22/blue-passports-issued-brits-first-time-decades-next-months-12281012/?ito=newsnow-feed" meta="11:10:20" />
            <NewsTicker id={4} title="Blue passports to be issued to Brits for the first time in decades next month" url=" https://metro.co.uk/2020/02/22/blue-passports-issued-brits-first-time-decades-next-months-12281012/?ito=newsnow-feed" meta="11:10:20" /> */}
          
          </Ticker>
           )}
        </div>
              {/* </a>
            ))} */}
          </div>
          :
          null}
         
      </div>
    );
  }
}

const mapStateToProps = ({
  firestore: { data },
  firebase: { auth, profile },
}: {
  firestore: FirestoreReducer.Reducer;
  firebase: FirebaseReducer.Reducer;
}) => {
  const loading =
    !isLoaded(data.incomingClaims) ||
    !isLoaded(data.outgoingClaims) ||
    !isLoaded(data.completedClaims) ||
    !isLoaded(data.partner);
  const incomingClaims = data.incomingClaims;
  const outgoingClaims = data.outgoingClaims;
  const completedClaims = data.completedClaims;
  const partner = data.partner;
  return {
    auth,
    loading,
    profile,
    partner,
    incomingClaims,
    outgoingClaims,
    completedClaims,
    firebase: getFirebase(),
  };
};

export default compose(connect(mapStateToProps))(DashboardPage);
