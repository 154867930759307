/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { downloadClaimDetail } from '../../actions/update-claim';
import React, { Component } from 'react';
class AsyncCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  openLinkInNewTab = (url) => {
    const newTab = window.open(url, "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <input
          className="sidebar-btn-text"
          type="button"
          value={loading ? "Downloading..." : "Download Report"}
          onClick={() =>
            //TODO as soon as testing is complete please change the hardcoded URI to variable
            this.openLinkInNewTab(
              "https://us-central1-glassclaims-2e876.cloudfunctions.net/downloadClaimDetailCsv"
            )
          }
          disabled={loading}
          color="new-claim"
        />
        {/* <a  
          type="button"
          href="https://us-central1-glassclaims-development.cloudfunctions.net/downloadClaimDetailCsv"
          value="facebook"
          target="_blank"
          class="button"
        >
          test
        </a> */}
        {/* <CSVLink
          headers={this.headers}
          data={data}
          filename="ClaimDetails.csv"
          ref={this.csvLinkEl}
        /> */}
      </div>
    );
  }
}

export default AsyncCSV;
