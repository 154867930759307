/* eslint-disable prettier/prettier */
import { Badge, Button } from 'reactstrap';
import { TileClaimsProps } from '../../claims/new/typings';
import React from 'react';
import _ from 'lodash';
import history from '../../../helpers/history';

const OutgoingClaims: React.FC<TileClaimsProps> = ({ claims, partner }) => {
  const unclaimed = _.reduce(
    _.map(claims, (c) => (c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0)),
    (sum, n) => sum + n,
    0,
  );
  const isDisabled = _.size(claims) <= 0;
  return (
    <Button className="claim-tile" disabled={isDisabled} onClick={() => history.push('/outgoing')}>
      {unclaimed > 0 ? (
        <Badge color="danger" className="claim-tile-badge">
          <span>{unclaimed}</span>
          <strong>Unassigned:</strong>
        </Badge>
      ) : (
        false
      )}
      <div className="counter">{_.size(claims)}</div>
      <div className="title">Sent Jobs</div>
      <div className="subtitle">Opened by {partner.name}</div>
    </Button>
  );
};

export default OutgoingClaims;
