/* eslint-disable prettier/prettier */
import 'firebase/auth';
import 'firebase/firestore'; // <- needed if using firestore
import 'firebase/functions';
import fb from 'firebase/app';

import { FirebaseOptions } from '@firebase/app-types';

const env = process.env.NODE_ENV;

const isDevDeploy = false;

// Replace this with your own config details
let appConfig: FirebaseOptions = {};

if (env === 'production' && !isDevDeploy) {
  appConfig = {
    apiKey: 'AIzaSyDPvP9Knl8AZ3TLurilrMXB6wzwo4ZGGJ4',
    authDomain: 'glassclaims-development.firebaseapp.com',
    databaseURL: 'https://glassclaims-development.firebaseio.com',
    projectId: 'glassclaims-development',
    storageBucket: 'glassclaims-development.appspot.com',
    messagingSenderId: '224337704671',
    appId: '1:224337704671:web:c0c302fd0d9c66abf11b6a',
  };
} else {
  appConfig = {
    apiKey: 'AIzaSyDPvP9Knl8AZ3TLurilrMXB6wzwo4ZGGJ4',
    authDomain: 'glassclaims-development.firebaseapp.com',
    databaseURL: 'https://glassclaims-development.firebaseio.com',
    projectId: 'glassclaims-development',
    storageBucket: 'glassclaims-development.appspot.com',
    messagingSenderId: '224337704671',
    appId: '1:224337704671:web:c0c302fd0d9c66abf11b6a',
  };
}

fb.initializeApp(appConfig);

// Init firestore
fb.firestore();

if (env !== 'production') {
  fb.functions().useFunctionsEmulator('http://localhost:5001');
}

export const firebase = fb;
export const auth = firebase.auth();
export const functions = fb.functions();
