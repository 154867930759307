/* eslint-disable prettier/prettier */
import { Box, Container, Link, Typography } from '@mui/material';
import { GITHUB_URL } from './constants';
import { NewsFeedProvider } from './newsFeedContext';
import EnhancedTable from './FeedTable';
import PostInput from './PostInput';
import React from 'react';
import Timeline from './Timeline';

const NewsFeed = () => (
  <>
    <Container maxWidth="sm">
      <NewsFeedProvider>
        <Box mt={2}>
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            News Feeds
            <hr />
          </Typography>
        </Box>
        {/* <Typography align="center" color="textSecondary" component="p" gutterBottom>
      This project uses React Hooks in order to render and manage the state of a simple news feed
      and its posts.
    </Typography> */}
        {/* <Typography
      align="center"
      color="textSecondary"
      variant="overline"
      component="p"
      gutterBottom>
      Visit my{' '}
      <Link href={GITHUB_URL} target="_blank">
        Github
      </Link>{' '}
      for more projects.
    </Typography> */}
        <PostInput/>
        {/* <Timeline /> */}
        &nbsp;
      </NewsFeedProvider>
    </Container>
    {/* <div style={{ height: '400px', width: '100%' }}>
      <div className="container">
        <EnhancedTable />
      </div>
    </div> */}
  </>
);

export default NewsFeed;
