/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import { Button, Input } from 'reactstrap/lib';
import { DropdownOption } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IncomingPageProps, IncomingPageState } from '../typings';
import { Label, Table } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { authorizedOptions } from '../../../helpers/utils';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EmptyState from '../../claims/empty-state/EmptyState';
import HeaderComponent from '../shared/table/HeaderComponent';
import Helmet from 'react-helmet';
import IncomingRow from '../shared/table/IncomingRow';
import IncomingSidePanel from '../side-panel/IncomingSidePanel';
import LoadingPage from '../../loading/LoadingPage';
import React, { Component } from 'react';
import _ from 'lodash';

const statusOptions = ['STATUS', 'NEW', 'INVOICED', 'SERVICE DONE', 'BOOKED', 'EXCESS_COLLECTED', 'ACCEPTED', 'CANCELLED']; // , 'COMPLETED'

class IncomingClaimPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & IncomingPageProps,
  IncomingPageState
> {
  private static INITIAL_STATE: IncomingPageState = {
    activeClaim: null,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(IncomingClaimPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);
    this.state = {
      ...IncomingClaimPage.INITIAL_STATE,
      statusFilter: { value: 'STATUS', label: 'ALL' },
      authorizedFilter: { value: 'Both', label: 'Both' },
      searchStringVal: '',
    };
    props.searchChange('');
  }

  public selectClaim(id: string | null) {
    this.setState(IncomingClaimPage.propKey('activeClaim', id));
  }

  options = statusOptions.map((status: string) => {
    if (status === 'STATUS') {
      return {
        value: status,
        label: 'ALL',
      };
    }
    return {
      value: status,
      label: status,
    };
  });

  authorizedOpts = authorizedOptions.map((status: string) => {
    return {
      value: status,
      label: status,
    };
  });

  setStatusFilter = (value: DropdownOption) => {
    this.setState({ statusFilter: value });
  };

  setAuthorizedFilter = (value: DropdownOption) => {
    this.setState({ authorizedFilter: value });
  };

  onSearchBtnClick = () => {
    typeof this.props.searchChange === 'function' &&
      this.props.searchChange(this.state.searchStringVal || '');
  };

  onResetSearchBtnClick = () => {
    this.setState({ searchStringVal: '' });
    typeof this.props.searchChange === 'function' && this.props.searchChange('');
  };

  componentDidUpdate() {
    const { claims } = this.props;
    const { activeClaim } = this.state;
    if (activeClaim && !claims[activeClaim]) {
      this.selectClaim(null);
    }
  }

  render() {
    const { claims, loading, partner, todos } = this.props;
    const { activeClaim } = this.state;
    const claim =
      claims && activeClaim && claims[activeClaim]
        ? { ...claims[activeClaim], id: activeClaim }
        : null;
    if (loading) {
      return <LoadingPage />;
    }

    if (_.isEmpty(claims)) {
      return <EmptyState message="There are currently no open incoming claims" />;
    }

    let claimsList = _.pickBy(claims, (c) => {
      if (this.state.authorizedFilter.value === 'Authorized') {
        return c.insurer.authorized === true;
      } else if (this.state.authorizedFilter.value === 'Unauthorized') {
        return c.insurer.authorized === false;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.statusFilter.value !== 'STATUS') {
        return c.status === this.state.statusFilter.value;
      }
      return c;
    });

    return (
      <div className={`incoming-claim-page-container ${activeClaim ? 'open' : 'closed'}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Received Jobs</title>
        </Helmet>
        <div className="page-title-div"> 
        <Label>Received Jobs</Label>
        </div>
        <div className="search-div">
          <Input
            name="search"
            value={this.state.searchStringVal}
            placeholder="Search Claim"
            className="search-input"
            onChange={(event) => this.setState({ searchStringVal: event.target.value })}
          />
          <Button onClick={this.onSearchBtnClick} className="search-btn">
            Search
          </Button>
          <Button
            outline
            color="secondary"
            onClick={this.onResetSearchBtnClick}
            className="search-btn">
            Reset
          </Button>
        </div>
        <Table>
          <HeaderComponent
            statusFilter={this.state.statusFilter}
            setStatusFilter={this.setStatusFilter}
            options={this.options}
            queue="Origin"
            authorizedOptions={this.authorizedOpts}
            authorizedFilter={this.state.authorizedFilter}
            setAuthorizedFilter={this.setAuthorizedFilter}
          />
          <tbody>
            {_.map(claimsList, (c, id: string) => (
              <IncomingRow
                key={`incoming-row-${id}`}
                claim={c}
                onClick={(e, claim) => this.selectClaim(id)}
              />
            ))}
          </tbody>
        </Table>
        <IncomingSidePanel
          claim={claim}
          todos={todos}
          partner={partner}
          onClose={() => this.selectClaim(null)}
        />
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
    searchString,
  }: {
    firebase: FirebaseReducer.Reducer;
    searchString: { searchString: string };
  } = state;
  return {
    profile: profile,
    searchString,
  };
};

const mapQueryToProps = ({
  profile,
  searchString,
}: {
  searchString: { searchString: string };
  profile: any;
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  if (searchString.searchString === '') {
    return [
      {
        collection: 'claims',
        where: [
          ['assign.partner', '==', profile.partnerId],
          ['status', 'in', ['NEW', 'CONTACTED', 'BOOKED', 'EXCESS_COLLECTED','SERVICE DONE', 'ACCEPTED', 'INVOICED', 'CANCELLED']], // ['active', '==', true],
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'config',
        doc: 'todos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  } else {
    return [
      {
        collection: 'claims',
        where: [
          ['insurer.claimNumber', '==', searchString.searchString],
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'claims',
        where: ['vehicle.registrationNumber', '==', searchString.searchString],        
        orderBy: ['_audit.created.timestamp', 'desc'],
        storeAs: 'claimsReg',
      },
      {
        collection: 'config',
        doc: 'todos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  searchChange: (searchVal: string) => {
    dispatch({
      type: 'SEARCH_CHANGE',
      value: searchVal,
    });
  },
});

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    searchString,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
  } = state;
  const partner = firestore.data.partner;
  const partners = firestore.data.partners;
  let claims = firestore.data.claims;
  if (searchString.searchString !== '') {
    if (firestore.data.claims) {
      claims = firestore.data.claims;
    } else if (firestore.data.claimsReg) {
      claims = firestore.data.claimsReg;
    }
  }
  const todosConfig = firestore.data.todosConfig;
  const loading = !isLoaded(claims) || !isLoaded(todosConfig) || !isLoaded(partner);
  let todos = null;
  if (!loading) {
    const masterTodo = todosConfig.todos;
    const partnerConfig = partner.claimTemplate.todos;
    todos = _.assign({}, masterTodo, partnerConfig);
  }
  return {
    loading,
    claims,
    todos,
    partner,
    partners,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(IncomingClaimPage);
