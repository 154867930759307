/* eslint-disable prettier/prettier */
import { Button } from 'reactstrap';
import { IClaim, IncomingTodoProps } from '../../typings';
import { IClaimGroupField, IStringKeyPair } from '../../new/typings';
import { IStateTodo, ITodo } from '../../../../../../typings';
import { Icon } from 'react-icons-kit';
import { checkSquare, file as fileIcon } from 'react-icons-kit/feather';
import {
  completeStep,
  invoiceStep,
  submitRatingForClaim,
  updateStep,
  uploadFile,
} from '../../../../actions/update-claim';
import { connect } from 'react-redux';
import { dotNotationReference } from '../../../../helpers/utils';
import { toast } from 'react-toastify';
import Checkbox from '../inputs/Checkbox';
import DatePicker from '../../new/ClaimForm/inputs/DatePicker';
import FileSingle from '../files/FileSingle';
import FilesViewModal from '../../../modals/FilesViewModal';
import FormatCurrency from '../../../shared/FormatCurrency';
import RateModal from '../../../modals/RateModal';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import SwitchControl from '../../new/ClaimForm/inputs/Switch';
import Textbox from '../../new/ClaimForm/inputs/Textbox';
import TodoFile from '../inputs/TodoFile';
import _ from 'lodash';
import moment from 'moment';

const initialState: {
  fields: IStringKeyPair;
  todos: { [key: string]: IStateTodo };
  reason: { [key: string]: string };
} = {
  fields: {},
  todos: {},
  reason: {},
};

const IncomingTodoList: React.FC<IncomingTodoProps> = ({
  claim,
  todos,
  completeStep,
  invoiceStep,
  uploadFile,
  isDisabled: isDisabledProp,
  submitRatingForClaim,
}) => {
  const [state, setState] = useState(initialState);
  const [claimId, setClaimId] = useState('');
  const [timeout, loadTimeout] = useState();
  const [fileLoading, setFileLoading] = useState<any>({});
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [rateModal, setRateModal] = useState(false);
  const updateObj = useRef<any>();

  // Update the state with claim data for steps
  // claim.todos[todo.key].completed
  useEffect(() => {
    if (!claim) {
      return;
    }
    let stateTodos: any = state.todos;
    const stateReasons: any = state.reason;
    if (claimId !== claim.insurer.claimNumber) {
      stateTodos = {};
      setClaimId(claim.insurer.claimNumber as string);
    }
    const fields: any = {};

    _.each(todos, (todo: ITodo) => {
      const dbCompleted = claim.todos[todo.key] ? claim.todos[todo.key].completed : false;
      const dbReason = claim.todos[todo.key]?.reason ? claim.todos[todo.key].reason : null;
      const completed = stateTodos[todo.key] ? stateTodos[todo.key].completed : dbCompleted;
      const reason = stateReasons[todo.key]?.reason ? stateReasons[todo.key].reason : dbReason;
      stateReasons[todo.key] = reason;
      stateTodos[todo.key] = {
        completed: completed,
        confirmed: dbCompleted,
      };
      fields[todo.key] = claim.metadata ? claim.metadata[todo.key] : false;
      if (todo.fields && todo.fields[todo.key] && todo.fields[todo.key].ref) {
        const ref = todo.fields[todo.key].ref;
        if (ref) {
          fields[todo.key] = dotNotationReference(ref, claim);
        }
      }
    });
    setState({
      ...state,
      todos: stateTodos,
      fields: fields,
      reason: stateReasons,
    });
    // we don't need exhaustive hook dep check, shut the linter up.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todos]);

  const updateStepApplicability = (id: string, key: string, reason: string) => {
    updateStep(id, key, reason, (err: Error) => {
      if (err) {
        console.error(err);
        return toast.error('An error occurred while updating the claim. Please try again later.');
      }
      setState({
        ...state,
        todos: {
          ...state.todos,
          [key]: {
            confirmed: true,
            completed: true,
          },
        },
      });
      const successMessage = (
        <div className="toast-message">
          <Icon icon={checkSquare} size={36} /> All done!
        </div>
      );
      return toast.success(successMessage, {
        autoClose: 3000,
        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
      });
    });
  };

  const updateTask = (claim: IClaim, todo: ITodo, value: any) => {
    const key = todo.key;
    let todoValues: any = [];
    if (!_.isEmpty(todo.fields)) {
      todoValues = _.map(todo.fields, (field, key) => {
        return {
          key,
          value: state.fields[key],
        };
      });
    }
    completeStep(claim.id, key, todoValues, (err: Error) => {
      if (err) {
        console.error(err);
        setState({
          ...state,
          todos: {
            ...state.todos,
            [key]: {
              confirmed: true,
              completed: false,
            },
          },
        });
        return toast.error('An error occurred while updating the claim. Please try again later.');
      }
      const successMessage = (
        <div className="toast-message">
          <Icon icon={checkSquare} size={36} /> All done!
        </div>
      );
      return toast.success(successMessage, {
        autoClose: 3000,
        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
      });
    });
  };

  const submitRating = (rating: number, reason = '') => {
    if (!claim) {
      return false;
    }
    if (typeof submitRatingForClaim === 'function') {
      submitRatingForClaim(claim.id || '', Number(rating), reason, (data: any, err: Error) => {
        if (err) {
          console.error(err);
          toast.error('Could not rate claim, please try again later.');
          return;
        } else {
          if (data) {
            toast.success('Successfully rated the claim');
          } else {
            toast.error('Could not rate claim, please try again later.');
          }
        }
        updateTask(updateObj.current.claim, updateObj.current.todo, updateObj.current.value);
      });
    }
  };

  const confirmRating = async (rating: number, reason = '') => {
    setRateModal(false);
    await submitRating(rating, reason);
  };

  const cancelRating = () => {
    setRateModal(false);
    updateTask(updateObj.current.claim, updateObj.current.todo, updateObj.current.value);
  };

  const debounceUpdate = (claim: IClaim, todo: ITodo, value: any) => {
    const key = todo.key;
    const completed = !state.todos[key].completed;
    setState({
      ...state,
      todos: {
        ...state.todos,
        [key]: {
          ...state.todos[key],
          completed: completed,
        },
      },
    });
    if (timeout) {
      clearTimeout(timeout);
      loadTimeout(null);
    }
    if (completed) {
      if (key !== 'proofOfPayment') {
        loadTimeout(setTimeout(() => updateTask(claim, todo, value), 1000));
      } else {
        updateObj.current = {
          claim,
          todo,
          value,
        };
      }
      if (key === 'collectedExcess') {
        if (Number(claim.insurer.excessValue) === 0) {
          updateStepApplicability(claim?.id || '', key, 'notApplicable');
        }
      } else if (key === 'proofOfPayment') {
        setRateModal(true);
      }
    }
  };

  const onChangeTodoInput = (fieldKey: string, value: any): void => {
    setState({
      ...state,
      fields: {
        ...state.fields,
        [fieldKey]: value,
      },
    });
  };

  const onDropFile = (todo: ITodo, field: IClaimGroupField, files: any): void => {
    if (!claim) {
      return;
    }
    fileLoading[todo.key] = true;
    setFileLoading(fileLoading);
    const id = claim.id as string;
    uploadFile(id, { files, key: todo.key }, (err: Error) => {
      fileLoading[todo.key] = false;
      setFileLoading(fileLoading);
      if (err) {
        console.error(err);
        return toast.error('Error uploading file, please try again later');
      }
      const successMessage = (
        <div className="toast-message">
          <Icon icon={checkSquare} size={36} /> Successfully uploaded file.
        </div>
      );
      return toast.success(successMessage, {
        autoClose: 3000,
        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
      });
    });
  };

  const renderInput = (
    todo: ITodo,
    fieldKey: string,
    field: IClaimGroupField,
    isActive: boolean,
  ): ReactElement | false => {
    const value = state.fields[fieldKey];
    switch (field.control) {
      case 'string':
        // Text field
        return (
          <Textbox
            key={fieldKey}
            type="text"
            value={value || ''}
            field={field}
            showLabel={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeTodoInput(fieldKey, e.target.value)
            }
          />
        );
      case 'number':
        return (
          <Textbox
            key={fieldKey}
            type="number"
            value={value || ''}
            field={field}
            showLabel={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeTodoInput(fieldKey, e.target.value)
            }
          />
        );
      case 'Timestamp':
        if (!value) {
          onChangeTodoInput(fieldKey, moment());
        }
        return (
          <DatePicker
            key={fieldKey}
            field={field}
            value={value || moment()}
            showLabel={false}
            onChange={(date: moment.Moment | null) => onChangeTodoInput(fieldKey, date)}
            withPortal={true}
          />
        );
      case 'boolean':
        return (
          <SwitchControl
            key={fieldKey}
            field={field}
            value={value || false}
            onChange={(checked: boolean) => onChangeTodoInput(fieldKey, checked)}
          />
        );
      case 'currency':
        if (Number(value) > 0) {
          return <FormatCurrency key={fieldKey} value={value} />;
        } else {
          const inputField = { ...field };
          inputField.label = 'NA';
          return (
            <SwitchControl
              key={fieldKey + 'NA'}
              field={inputField}
              value={state.reason['collectedExcess'] === 'notApplicable'}
              formClass="todo-toggle-div"
              onChange={(checked: boolean) => {
                if (checked) {
                  updateStepApplicability(claim?.id || '', fieldKey, 'notApplicable');
                } else {
                  updateStepApplicability(claim?.id || '', fieldKey, 'applicable');
                }
              }}
            />
          );
        }
      case 'FileUpload':
        const hasFile = claim && claim.files && claim.files[field.key] ? true : false;
        if (hasFile && claim) {
          return (
            <FileSingle
              key={field.key}
              file={field.key}
              claim={claim}
              field={field}
              hideDelete={false}
            />
          );
        } else if (
          field.label === 'Calibration Certificate' &&
          state.reason['calibrationCertificate'] === 'notApplicable'
        ) {
          const inputField = { ...field };
          inputField.label = 'NA';
          return (
            <SwitchControl
              key={fieldKey + 'NA'}
              field={inputField}
              value={state.reason['calibrationCertificate'] === 'notApplicable'}
              formClass="todo-toggle-div"
              onChange={(checked: boolean) => {
                if (checked) {
                  updateStepApplicability(claim?.id || '', fieldKey, 'notApplicable');
                } else {
                  updateStepApplicability(claim?.id || '', fieldKey, 'applicable');
                }
              }}
            />
          );
        } else if (field.label === 'Calibration Certificate') {
          const inputField = { ...field };
          inputField.label = 'NA';
          return (
            <>
              <SwitchControl
                key={fieldKey + 'NA'}
                field={inputField}
                value={state.reason['calibrationCertificate'] === 'notApplicable'}
                formClass="todo-toggle-div"
                onChange={(checked: boolean) => {
                  if (checked) {
                    updateStepApplicability(claim?.id || '', fieldKey, 'notApplicable');
                  } else {
                    updateStepApplicability(claim?.id || '', fieldKey, 'applicable');
                  }
                }}
              />
              <TodoFile
                loading={fileLoading && fileLoading[field.key] ? fileLoading[field.key] : false}
                queue="incoming"
                key={field.key}
                onDrop={(files: Array<{ file: string; ext: string }>) =>
                  onDropFile(todo, field, files)
                }
                onError={toast.error}
                className="todo-drop"
                field={field}
                hideText={!isActive}
              />
            </>
          );
        } else {
          return (
            <TodoFile
              loading={fileLoading && fileLoading[field.key] ? fileLoading[field.key] : false}
              queue="incoming"
              key={field.key}
              onDrop={(files: Array<{ file: string; ext: string }>) =>
                onDropFile(todo, field, files)
              }
              onError={toast.error}
              className="todo-drop"
              field={field}
            />
          );
        }
      default:
        // no idea, so let's play it safe;
        console.error(
          `Invalid field control type ${field.control}, please check the type and try again`,
        );
        return false;
    }
  };

  const renderInputs = (todo: ITodo, isActive: boolean): ReactElement[] => {
    return _.compact(
      _.map(todo.fields, (field, fieldKey) => renderInput(todo, fieldKey, field, isActive)),
    );
  };

  const renderTodo = (todo: ITodo, claim: IClaim): ReactElement | false => {
    const isCompleted = state.todos[todo.key].completed === true;
    const isConfirmed = state.todos[todo.key].confirmed === true;
    // Find out exactly where we are at in the progress
    const todoKeys = _.keys(state.todos);
    const todoIndex = _.indexOf(todoKeys, todo.key);
    const previous = Math.max(0, todoIndex - 1);
    const previousKey = todoKeys[previous];
    const previousTodo = state.todos[previousKey];
    // Invoke the mighty meeseeks
    const ooWeeCanDoo = previous === todoIndex || previousTodo.completed === true;
    const isDisabled = isCompleted || !ooWeeCanDoo || isDisabledProp;
    const isActive = !isCompleted && ooWeeCanDoo && !isDisabledProp;

    let hasFile = false;
    let isFileField = false;
    // Check if we have a file
    const fileFields = _.compact(
      _.map(todo.fields, (field) => {
        if (field.control === 'FileUpload') {
          isFileField = true;
          return field.key;
        }
      }),
    );
    if (fileFields && !_.isEmpty(fileFields) && claim.files) {
      const claimHasFiles = claim && claim.files;
      const hasAtLeastOneFile = _.some(fileFields, (field) => {
        return _.isArray(claim.files[field]);
      });
      if (claimHasFiles && hasAtLeastOneFile) {
        hasFile = true;
      }
    }

    const disabledClass = !ooWeeCanDoo || isDisabledProp ? 'disabled' : '';
    const completedClass = isCompleted ? 'complete' : '';
    const confirmedClass = isConfirmed ? 'confirmed' : '';
    const hasFileClass = isFileField ? (!hasFile ? 'file' : 'file has-file') : '';
    return (
      <div
        className={`claim-todo ${disabledClass} ${completedClass} ${hasFileClass} ${confirmedClass}`}
        key={`todo-${todo.key}`}>
        <Checkbox
          key={todo.key}
          value={state.todos[todo.key].completed}
          onChange={(value) => debounceUpdate(claim, todo, value)}
          label={todo.label}
          disabled={isDisabled}
        />
        {/* <div className="loader">
          <Icon icon={loader} className="spin" />
        </div> */}
        {!_.isEmpty(todo.fields) ? renderInputs(todo, isActive) : false}
      </div>
    );
  };

  if (!claim || _.isEmpty(state.todos)) {
    return <div></div>;
  }

  return (
    <div className="todo-list">
      <div className="files-attached-div">
        <h3>To Do List</h3>
        {claim.files && (
          <Button
            className="file-single"
            color="link"
            onClick={() => setFileModalOpen(true)}
            size="sm">
            <Icon icon={fileIcon} />
          </Button>
        )}
      </div>
      {_.map(todos, (todo) => renderTodo(todo, claim))}
      {claim.files && (
        <FilesViewModal
          onClose={() => {
            setFileModalOpen(false);
          }}
          isOpen={fileModalOpen}
          files={claim.files}
        />
      )}
      <RateModal
        partnerName={claim.assign.origin || ''}
        onConfirm={confirmRating}
        onCancel={cancelRating}
        isOpen={rateModal}
        claimId={claim.id || ''}
        ratingFlow="origin"
      />
    </div>
  );
};

export default connect(null, {
  completeStep,
  invoiceStep,
  uploadFile,
  submitRatingForClaim,
})(IncomingTodoList);
