/* eslint-disable prettier/prettier */
import '../../../styles/Ticker.scss';
import "../NewsTicker";
import "../NewsTicker/style.scss";
import * as React from 'react';

interface NewsTickerProps {
  id: number;
  title: string;
  url: string;
  meta: string;
  icon?: string;
  iconAlt?: string;
}

const NewsTicker: React.FunctionComponent<NewsTickerProps> = (props) => {
  return (

    // <div className="newsticker tickerItem iconNews newsWrapper newsTitle">
    //   {/* <img src={props.icon} alt={props.iconAlt} /> */}
    //   {props.date} {props.title} {props.news} {' '} By Admin
    // </div>

    <a className="newsticker tickerItem" href={props.url} target="_blank" rel="noreferrer">
      <div className="iconNews"><img src={props.icon} alt={props.iconAlt} /></div>
      <div className="newsWrapper">
        <small className="meta">{props.meta}&nbsp;&nbsp;     </small>
        <div className="newsTitle">{props.title}</div>        
      </div>
    </a>
  );
};

export default NewsTicker;
